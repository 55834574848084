<template>
    <div v-if="book_id">
        <div id="bookFace">
            <input @change="loadImage($event)" type="file" ref="file" class="d-none" accept="image/*" capture>

            <div class="top" ref="top">
                <div id="back" @click="useBackButton">
                    <i class="fas fa-chevron-left"></i>
                </div>

                <div v-if="bookfaceCreated" class="title" style="height: auto;">
                    Twój #Bookface jest gotowy
                </div>

                <div class="icons">
<!--                    ic-->
                </div>
            </div>

            <div v-if="!bookfaceCreated && imageLoaded && cover" class="image-wrapper" :style="{backgroundImage: 'url(' + this.editedImage + ')'}" ref="editor">
                <img class="cover-image" ref="coverImage"
                     v-hammer:pan="manipulate"
                     v-hammer:pinch="manipulate"
                     v-hammer:panend="adjustEnd"
                     v-hammer:panstart="adjustStart"
                     v-hammer:pinchend="adjustEnd"
                     v-hammer:pinchstart="adjustStart"
                     v-hammer:rotatestart="adjustStart"
                     v-hammer:rotateend="adjustEnd"
                     :src="coverUrl + cover" alt="">
            </div>

            <div v-if="bookfaceCreated" class="image-wrapper" :style="{backgroundImage: 'url(' + bookfaceUrl + this.bookface + ')'}"></div>

            <div v-if="!bookfaceCreated" class="bottom justify-content-between" ref="bottom">
                <a class="change-image" @click="$refs.file.click()" :style="{backgroundImage: 'url(' + this.editedImage + ')'}"></a>

                <div v-if="imageLoaded" class="text-center">
                    <a @click="createBookface(cover)" class="bookface-create d-block mb-2">Dalej</a>
                </div>

                <div v-else class="text-center">
                    <a @click="$refs.file.click()" class="bookface-create d-block mb-2">Zrób zdjęcie</a>
                </div>
            </div>

            <div v-else class="bottom created" ref="bottom">
                <div v-if="!imageLoaded" class="bottom-box text-center">
                    <a class="change-image" @click="$refs.file.click()" :style="{backgroundImage: 'url(' + this.editedImage + ')'}"></a>
                    <span class="small-text">Zrób zdjęcie</span>
                </div>
                <div v-else class="bottom-box mr-auto text-center">
                    <a class="bookface-download d-block mb-2" @click="download(bookfaceUrl + bookface)"></a>
                    <span class="small-text">Zapisz</span>
                </div>

                <div v-if="imageLoaded" class="bottom-box text-center">
                    <a class="bookface-share d-block mb-2" @click="share(bookfaceUrl + bookface)"></a>
                    <span class="small-text">Udostępnij</span>
                </div>

                <div v-if="imageLoaded" class="bottom-box ml-auto text-center" @click="publishBookface">
                    <a class="bookface-publish d-block mb-2">Opublikuj</a>
                    <span class="small-text">w Bookme</span>
                </div>
            </div>

            <div v-if="messageVisible" id="message">
                <p>Przesałno plik o nieprawidłowym formacie. Spróbuj zrobić nowe zdjęcie</p>

                <a class="message-button" @click="newPhoto">Zrób nowe zdjęcie</a>
            </div>
        </div>

        <div class="bg-gray" v-if="loaderVisible">
            <Loader :text="this.loaderText" />
        </div>
    </div>
</template>
<script>

import Loader from '../components/Loader'
export default {
  name: 'BookfaceCreate',
  components: {
    Loader
  },
  props: {
    book_id: String
  },
  data () {
    return {
      cover: '',
      image: null,
      editedImage: null,
      stopPan: false,
      imageLoaded: false,
      loaderVisible: false,
      bookfaceCreated: false,
      bookface: '',
      loaderText: '',
      messageVisible: false,
      coverData: {
        currentScale: 1,
        adjustScale: 1,
        currentRotation: 0,
        adjustRotation: 0,
        currentDeltaX: 0,
        currentDeltaY: 0,
        adjustDeltaX: 0,
        adjustDeltaY: 0
      }
    }
  },
  computed: {
    my_id () { return this.$store.state.userData.userId }
  },
  created () {

  },
  mounted () {
    this.$refs.file.click()
  },
  watch: {
    '$route' (to, from) {

    }
  },
  methods: {
    useBackButton () {
      this.$router.go(-1)
    },
    getCover () {
      this.$https.get('/bookface/get-book-cover/' + this.book_id, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(res => {
          this.cover = res.data.response
        })
        .catch(error => {
          console.log('', error)
        })
    },
    loadImage (event) {
      this.loaderText = 'Trwa wczytywanie'
      this.loaderVisible = true
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.editedImage = e.target.result
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
        this.image = input.files[0]
        this.getCover()
        this.imageLoaded = true
        this.loaderVisible = false
        this.loaderText = ''
      }
    },
    manipulate (e) {
      const elem = e.target
      if (e.type === 'pan' && this.stopPan) {
        return
      }
      this.coverData.currentRotation = this.coverData.adjustRotation + e.rotation
      this.coverData.currentScale = this.coverData.adjustScale * e.scale
      this.coverData.currentDeltaX = this.coverData.adjustDeltaX + e.deltaX / this.coverData.currentScale
      this.coverData.currentDeltaY = this.coverData.adjustDeltaY + e.deltaY / this.coverData.currentScale

      const transforms = ['scale(' + this.coverData.currentScale + ')']
      transforms.push('translate(' + this.coverData.currentDeltaX + 'px,' + this.coverData.currentDeltaY + 'px)')
      transforms.push('rotate(' + Math.round(this.coverData.currentRotation) + 'deg)')
      elem.style.transform = transforms.join(' ')
    },
    adjustStart (e) {
      this.coverData.adjustRotation -= e.rotation
      if (e.type === 'pinchstart') {
        this.stopPan = true
      }
    },
    adjustEnd (e) {
      this.setAdjustedValues()

      if (this.$refs.coverImage.getBoundingClientRect().width > this.$refs.editor.clientWidth) {
        this.resetPosition(e.target, 'wide')
      }

      if (this.$refs.coverImage.getBoundingClientRect().height > this.$refs.editor.clientHeight) {
        this.resetPosition(e.target, 'high')
      }

      if (this.$refs.coverImage.getBoundingClientRect().x > this.$refs.editor.clientWidth - this.$refs.coverImage.getBoundingClientRect().width / 2) {
        this.resetPosition(e.target, 'right')
      }

      if (this.$refs.coverImage.getBoundingClientRect().x < 0 - this.$refs.coverImage.getBoundingClientRect().width / 2) {
        this.resetPosition(e.target, 'left')
      }

      if (this.$refs.coverImage.getBoundingClientRect().y - this.$refs.top.clientHeight > this.$refs.editor.clientHeight - this.$refs.coverImage.getBoundingClientRect().height / 2) {
        this.resetPosition(e.target, 'bottom')
      }

      if (this.$refs.coverImage.getBoundingClientRect().y - this.$refs.top.clientHeight < 0 - this.$refs.coverImage.getBoundingClientRect().height / 2) {
        this.resetPosition(e.target, 'top')
      }
      this.setAdjustedValues()
      if (e.type === 'pinchend') {
        var self = this
        setTimeout(function () {
          self.stopPan = false
        }, 100)
      }
    },
    setAdjustedValues () {
      this.coverData.adjustScale = this.coverData.currentScale
      this.coverData.adjustRotation = this.coverData.currentRotation
      this.coverData.adjustDeltaX = this.coverData.currentDeltaX
      this.coverData.adjustDeltaY = this.coverData.currentDeltaY
    },
    resetPosition (e, side) {
      if (side === 'right') {
        this.coverData.currentDeltaX = this.$refs.editor.clientWidth / this.coverData.currentScale - this.$refs.coverImage.clientWidth / this.coverData.currentScale / 2
      }
      if (side === 'left') {
        this.coverData.currentDeltaX = -this.$refs.coverImage.clientWidth / this.coverData.currentScale / 2
      }
      if (side === 'top') {
        this.coverData.currentDeltaY = -this.$refs.coverImage.clientHeight / this.coverData.currentScale / 2
      }
      if (side === 'bottom') {
        this.coverData.currentDeltaY = this.$refs.editor.clientHeight / this.coverData.currentScale - this.$refs.coverImage.clientHeight / this.coverData.currentScale / 2
      }
      if (side === 'wide') {
        this.coverData.currentScale = this.$refs.editor.clientWidth / this.$refs.coverImage.clientWidth
      }
      if (side === 'high') {
        this.coverData.currentScale = this.$refs.editor.clientHeight / this.$refs.coverImage.clientHeight
      }
      const transforms = ['scale(' + this.coverData.currentScale + ')']
      transforms.push('translate(' + this.coverData.currentDeltaX + 'px,' + this.coverData.currentDeltaY + 'px)')
      transforms.push('rotate(' + Math.round(this.coverData.currentRotation) + 'deg)')
      e.style.transform = transforms.join(' ')
    },
    createBookface () {
      this.loaderText = 'Trwa tworzenie Twojego #Bookface'
      this.loaderVisible = true
      const data = new FormData()
      data.append('image', this.editedImage)
      data.append('book_id', this.book_id)
      data.append('x', this.$refs.coverImage.getBoundingClientRect().x)
      data.append('y', this.$refs.coverImage.getBoundingClientRect().y - this.$refs.top.clientHeight)
      data.append('scale', this.coverData.currentScale)
      data.append('rotation', this.coverData.currentRotation)
      data.append('imageWidth', this.$refs.editor.clientWidth)
      data.append('imageHeight', this.$refs.editor.clientHeight)
      this.$https.post('/bookface/bookface-create', data, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
        if (res.data.message === 'did not match data URI with image data') {
          this.loaderVisible = false
          this.messageVisible = true
        }
        this.bookface = res.data.response.bookfaceUrl
        this.bookfaceCreated = true
        this.loaderVisible = false
        this.loaderText = ''
      })
        .catch(error => {
          console.log('', error)
        })
    },
    publishBookface () {
      this.loaderText = 'Trwa publikowanie Twojego #Bookface'
      this.loaderVisible = true
      const data = new FormData()
      data.append('image', this.bookface)
      data.append('book', this.book_id)
      this.$https.post('/bookface/bookface-save', data, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
        this.loaderVisible = false
        this.loaderText = ''
        if (res.data.response === 'success') {
          this.$router.go(-1)
        }
      })
        .catch(error => {
          console.log('', error)
        })
    },
    share (url) {
      window.location.href = 'gonative://share/sharePage?url=' + encodeURIComponent(url)
    },
    async download (url) {
      window.location.href = 'gonative://share/downloadFile?url=' + encodeURIComponent(url)
    },
    newPhoto () {
      this.messageVisible = false
      this.$refs.file.click()
    }
  }
}
</script>
<style lang="scss" scoped>
    .loader-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 500;
        background: rgba(255,255,255,0.9);
    }
    #bookFace{
        background: #1f1f1f;
        min-height: 100vh;
        width: 100vw;
        color: #fff;

        .top{
            padding: 20px 27px 30px 0;
            width: 100%;
            height: 13vh;
            z-index: 50;
            display: flex;
            justify-content: space-between;
            align-items: center;

            #back{
                z-index: 20;
                color: white;
                font-size: 22px;
                padding: 0 27px;
                width: 75px;
            }

            .title{
                text-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                text-align: center;
                flex: 1;
            }

            .icons{
                padding-left: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .image-wrapper{
            position: relative;
            height: 64vh;
            background-size: 100%;
            background-repeat: no-repeat;
            overflow: hidden;

            .cover-image{
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .bottom{
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 50px 30px 35px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 23vh;
            z-index: 50;

            .change-image{
                display: block;
                width: 7vw;
                height: 7vw;
                min-width: 26px;
                min-height: 26px;
                background: #d8d8d8;
                border-radius: 5px;
                background-size: cover;
            }

            &.created{
                .change-image{
                    margin: 0 auto 10px;
                }
            }

            .bottom-box{
                flex: 1;
            }

            .bookface-download{
                background: transparent url('../assets/images/download-icon.svg') no-repeat center;
                width: 29px;
                height: 29px;
                margin: 0 auto;
            }

            .bookface-share{
                background: transparent url('../assets/images/send-icon.svg') no-repeat center;
                width: 29px;
                height: 29px;
                margin: 0 auto;
            }

            .bookface-create{
                padding: 3px 15px;
                border-radius: 12px;
                border: solid 1px #ffbc00;
                color: #ffbc00;
                font-size: 16px;

                &:hover{
                    background: #ffbc00;
                    color: #000000;
                }
            }

            .bookface-publish{
                background: #ffbc00;
                padding: 3px 15px;
                border-radius: 12px;
                border: solid 1px #ffbc00;
                color: #000000;
                font-size: 16px;
                height: 29px;
                display: flex;
                align-items: center;

                &:hover{
                    color: #ffbc00;
                    background: none;
                }
            }

            .small-text{
                font-size: 11px;
            }
        }

        #message{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(255,255,255,0.9);
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 500;
            font-family: Roboto, sans-serif;
            font-size: 16px;
            line-height: 1.63;
            text-align: center;
            padding: 50px;

            .message-button{
                background: #000;
                color: #fff;
                padding: 12px;
            }
        }
    }
</style>
